import React from 'react';

export default function Team() {
    return (
        <div>
            <h4 className="mb-3">Team</h4>
            <h5>PhD candidates</h5>
            <ul>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/i.besnea/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Irina Besnea
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/j.a.vanthoff/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Jurriaan van &apos;t Hoff
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/l.kavabata/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Luccas Kavabata
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/j.maruhashi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Jin Maruhashi
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/f.quadros/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Flávio Quadros
                    </a>{' '}
                    (defended on 01/2024)
                </li>
                <li>Zain Robson</li>
            </ul>
            <h5>Postdocs</h5>
            <ul>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/f.quadros/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Dr Flávio Quadros
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/j.maruhashi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Jin Maruhashi
                    </a>
                </li>
            </ul>
            <h5>MSc students</h5>
            <ul>
                <li>Samuel Otterloo (2022-2024)</li>
                <li>Yvar Vliex (2023-2024)</li>
                <li>Xander van Hal (2023-2024)</li>
                <li>Jeltes de Weerd (at to70)(2023-2024)</li>
                <li>Tom van Cranenburgh (2023-2024)</li>
                <li>Jaime Aalders (2023-2024)</li>
                <li>Lidia Rzeplinska (2021-2023)</li>
                <li>Edoardo Verzotti (2022-2023)</li>
                <li>Rik Goudswaard (2021-2023)</li>
                <li>Rick Nelen (2022-2023)</li>
                <li>Liesbeth Wijn (2022-2023)</li>
                <li>Harry Aldridge (2020-2022)</li>
                <li>Mathieu Blanke (2020-2021)</li>
                <li>Lodewijck Foorthuis (at SRON, 2021-2022)</li>
                <li>Mariijn van Loo (2020-2021)</li>
                <li>Jeff Maes (2020-2021)</li>
                <li>Pratik Rindhe (at SRON, 2021-2022)</li>
                <li>Vincent Verhoeven (2019-2020) </li>
            </ul>
            <h5>Other</h5>
            <ul>
                <li>
                    <a
                        href="https://www.tudelft.nl/staff/j.maes/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Jeff Maes
                    </a>
                    , Researcher, 2021-present
                </li>
                <li>Vincent Verhoeven, Researcher, 2020-2021</li>
                <li>
                    Morgane Bourgeois, MSc Exchange Research Internship, 2020
                </li>
            </ul>
        </div>
    );
}
